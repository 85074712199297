<template>
  <UserLayout :title="title" :showBtn="showButton" :buttonText="buttonText">
    <h2>Home</h2>
  </UserLayout>
</template>

<script>
import UserLayout from "@/components/Layout/UserLayout.vue";

export default {
  data() {
    return {
      isLoading: true,
      title: "Openedx Extension",
      showButton: false,
      buttonText: ""
    };
  },
  components: {
    UserLayout
  }
};
</script>
